import React from "react";
import "./nfts.scss";
import Navbar from "../../components/Navbar";
import card1 from "../../static/images/bgs/card1.svg";
import card2 from "../../static/images/bgs/card2.svg";
import card3 from "../../static/images/bgs/card3.svg";
import card4 from "../../static/images/bgs/card4.svg";
import { useNavigate } from "react-router-dom";

function NFTs() {
  let navigate = useNavigate();

  const openreg = (e) => {
    navigate("/registration");
  };
  return (
    <>
      <Navbar active="nfts" />
      {window.innerWidth > 768 ? (
        <div className="nftsbg">
          <div className="nfts-card">
            <p className="nfts-to-remember">Own Part Of IndianNights</p>
            <p className="nfts-tag">With Our Exclusive Crown Card</p>
            <button className="nftbtn" onClick={openreg}>
              <svg
                className="fire-icon"
                width="20"
                height="25"
                viewBox="0 0 20 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.332424 11.944C0.332424 11.944 1.29999 13.3654 3.32793 14.5686C3.32793 14.5686 1.85921 2.1558 11.4289 0C8.97708 8.99844 14.5476 11.5361 16.5782 6.78208C19.9692 11.3095 17.3945 15.2595 17.3945 15.2595C18.785 15.4598 19.9529 13.9408 19.9529 13.9408C19.9634 14.1279 19.9692 14.3163 19.9692 14.5061C19.9693 20.0204 15.499 24.4906 9.98464 24.4906C4.47029 24.4906 3.05176e-05 20.0204 3.05176e-05 14.506C3.05176e-05 13.6202 0.115978 12.7616 0.332424 11.944Z"
                  fill="white"
                />
                <path
                  d="M19.953 13.9408C19.953 13.9408 18.785 15.4598 17.3946 15.2595C17.3946 15.2595 19.9693 11.3095 16.5782 6.78208C14.5477 11.5361 8.97715 8.99844 11.4289 0C10.9164 0.115469 10.4358 0.260548 9.98466 0.43136V24.4906C15.499 24.4906 19.9693 20.0204 19.9693 14.506C19.9693 14.3162 19.9634 14.1279 19.953 13.9408Z"
                  fill="white"
                />
                <path
                  d="M5.6058 20.1117C5.6058 22.5301 7.5663 24.4906 9.98465 24.4906C12.403 24.4906 14.3635 22.5301 14.3635 20.1117C14.3635 18.8173 13.8019 17.6541 12.909 16.8525C11.216 19.1525 8.80336 15.6635 10.6836 13.1157C10.6836 13.1157 5.6058 13.752 5.6058 20.1117Z"
                  fill="#FAB544"
                />
                <path
                  d="M14.3635 20.1117C14.3635 18.8173 13.8019 17.6541 12.909 16.8525C11.216 19.1525 8.80338 15.6635 10.6836 13.1157C10.6836 13.1157 10.4077 13.1503 9.98466 13.2838V24.4906C12.403 24.4906 14.3635 22.5301 14.3635 20.1117Z"
                  fill="#FAB544"
                />
              </svg>
              Join Waiting List
            </button>
          </div>

          <div className="right">
            <div className="innerbackground">
              <div className="leftsidesection">
                <img src={card1} />
              </div>
              <div className="rightsideSection">
                <div className="top">
                  <img src={card2} />
                </div>
                <div className="bottom">
                  <img src={card3} />
                </div>
              </div>
            </div>
            <div className="outerinerbackground">
              {" "}
              <img src={card4} />{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="nftmbbg">
          <div className="nft-mb-title">
            <p className="nft-mb-title-text">Own Part Of</p>
            <p className="nft-mb-title-text">IndianNights</p>
            <div className="nft-mb-desc">
              <p>With Our Exclusive Crown Card</p>
            </div>
            <div>
              <button className="pre-reg-mb-btn" onClick={openreg}>
                <svg
                  className="fire-icon"
                  width="20"
                  height="25"
                  viewBox="0 0 20 25" 
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.332424 11.944C0.332424 11.944 1.29999 13.3654 3.32793 14.5686C3.32793 14.5686 1.85921 2.1558 11.4289 0C8.97708 8.99844 14.5476 11.5361 16.5782 6.78208C19.9692 11.3095 17.3945 15.2595 17.3945 15.2595C18.785 15.4598 19.9529 13.9408 19.9529 13.9408C19.9634 14.1279 19.9692 14.3163 19.9692 14.5061C19.9693 20.0204 15.499 24.4906 9.98464 24.4906C4.47029 24.4906 3.05176e-05 20.0204 3.05176e-05 14.506C3.05176e-05 13.6202 0.115978 12.7616 0.332424 11.944Z"
                    fill="white"
                  />
                  <path
                    d="M19.953 13.9408C19.953 13.9408 18.785 15.4598 17.3946 15.2595C17.3946 15.2595 19.9693 11.3095 16.5782 6.78208C14.5477 11.5361 8.97715 8.99844 11.4289 0C10.9164 0.115469 10.4358 0.260548 9.98466 0.43136V24.4906C15.499 24.4906 19.9693 20.0204 19.9693 14.506C19.9693 14.3162 19.9634 14.1279 19.953 13.9408Z"
                    fill="white"
                  />
                  <path
                    d="M5.6058 20.1117C5.6058 22.5301 7.5663 24.4906 9.98465 24.4906C12.403 24.4906 14.3635 22.5301 14.3635 20.1117C14.3635 18.8173 13.8019 17.6541 12.909 16.8525C11.216 19.1525 8.80336 15.6635 10.6836 13.1157C10.6836 13.1157 5.6058 13.752 5.6058 20.1117Z"
                    fill="#FAB544"
                  />
                  <path
                    d="M14.3635 20.1117C14.3635 18.8173 13.8019 17.6541 12.909 16.8525C11.216 19.1525 8.80338 15.6635 10.6836 13.1157C10.6836 13.1157 10.4077 13.1503 9.98466 13.2838V24.4906C12.403 24.4906 14.3635 22.5301 14.3635 20.1117Z"
                    fill="#FAB544"
                  />
                </svg>
                Join Waiting List
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default NFTs;
