import React from "react";
import ShorupanImg from "../../../static/images/logo/shorupanImg.png"
import KrishnaImg from "../../../static/images/logo/KrishnaImg.png"
import ShorupanMbImg from "../../../static/images/logo/ShorupanMbImg.png"
import KrishnaMbImg from "../../../static/images/logo/KrishnaMbImg.png"
import "./founders.scss";
import "../about.scss";
import ScrollSpy from 'react-scrollspy';
import Navbar from "../../../components/Navbar";
import AboutFooter from "../AboutFooter/aboutfooter";




function Founders() {
    return (
        <>
            <Navbar active="founders" />
            <section id="founders" key="2">
                {window.innerWidth > 768 ?
                    (<>
                        <div className="the-width-adj">
                            <div className="row">
                                <div className="col-sm-8">
                                    <div className="left-data">
                                        <p className="about-founder-1">Shorupan Pirakaspathy</p>
                                        <p className="co-founder">Co-Founder</p>
                                        <p className="founder-1-desc">Shorupan is the CEO and Founder of the INR.Group
                                            which is India’s first tokenized venture capital
                                            fund focused on the cryptocurrency industry. IndianNights
                                            is a key component of the INR.Group porftolio.</p>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <img src={ShorupanImg} alt="shorupan-img" className="right-img-founder" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <img className="img-founder" src={KrishnaImg} alt="krishna-img" />
                                </div>
                                <div className="col-sm-8">
                                    <div className="left-data">
                                        <p className="about-founder-1">K Y Krishna</p>
                                        <p className="co-founder">Co-Founder</p>
                                        <p className="founder-2-desc">Krishna is one of India’s most promising
                                            entrepreneurs with prolific stints at Nvest, IndianInvestor and now has
                                            partnered with INR.Group to bring IndianNights to the market.</p>
                                    </div>
                                </div>
                            </div>
                        </div></>) : (
                        <>
                            <section id="founders" key="2">
                                <div className="the-data">
                                    <p className="founder-sty">Shorupan P</p>
                                    <p className="founder-text">Co-Founders</p>
                                    <img src={ShorupanMbImg} alt="shorupan-img" className="founder-img" />
                                    <p className="founder-desc">Shorupan is the CEO and Founder of the INR.Group
                                        which is India’s first tokenized venture capital
                                        fund focused on the cryptocurrency industry. IndianNights
                                        is a key component of the INR.Group porftolio.</p>
                                </div>
                                <br />
                                <div className="the-data the-margin">
                                    <p className="founder-sty">K Y Krishna</p>
                                    <p className="founder-text">Co-Founders</p>
                                    <img src={KrishnaMbImg} alt="shorupan-img" className="founder-img" />
                                    <p className="founder-desc">Krishna is one of India’s most promising
                                        entrepreneurs with prolific stints at Nvest, IndianInvestor and now
                                        has partnered with INR.Group to bring IndianNights to the market.</p>
                                </div>
                                {/* <ScrollSpy className="about-footer" items={['founders']} currentClassName="isCurrent">
                                    <li className="bottom-text"><a href="#founders">Founders</a></li>
                                    <p className="bottom-text">Apps</p>
                                    <p className="bottom-text">Services</p>
                                    <p className="bottom-text">Media</p>
                                </ScrollSpy> */}
                                {/* <AboutFooter active="founders" status = {false}/> */}
                            </section>
                        </>
                    )}
            </section>
        </>
    )
}

export default Founders