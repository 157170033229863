import React, { useState } from "react";
import "./login.css";
import IndianNightLogo from "../../static/images/logos/Indiannightlogo.png"
import Navbar from "../../components/Navbar";
import BackArrowIcon from "../../static/images/logo/BackArrow.png"
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
// import "antd/dist/antd.css";



function Login() {
    let navigate = useNavigate()
    const [mobilereg, setmobilreg] = useState("mobilepage")
    const [data, setdata] = useState("page")
    const [useremail, setuseremail] = useState(localStorage.getItem('email') || '')
    const [userpass, setuserpass] = useState("")
    const [btnop, setbtnop] = useState("0.5")
    const [Loading, setLoading] = useState(false)



    let themobiledisp
    let themobilebtn

    let thedisp
    let thebtn

    const logindata = (e) => {
        e.preventDefault()
        if (!userpass) {
            toast("Please enter your password!")
        } else {
            setLoading(true)
            // console.log(useremail, userpass)
            axios.post('https://gxauth.apimachine.com/gx/user/auth/login', {
                email: useremail,
                password: userpass,
            })
                .then((response) => {
                    const { data } = response;
                    if (data.status) {
                        setLoading(false)
                        console.log("user login success")
                        // console.log(response.data.user)
                        localStorage.setItem('email', response.data.user.email);
                        localStorage.setItem('username', response.data.user.username);
                        localStorage.setItem('picimg' , response.data.user.profile_img)
                        toast("User login success")
                        navigate("/app/tickets")
                    } else {
                        setLoading(false)
                        console.log(response.data.message)
                        toast(response.data.message)
                    }
                })
        }
    }

    const firstpage = (e) => {
        setdata("page")
        setmobilreg("mobilepage")
        if (useremail === "") {
            setbtnop("0.5")
        } else {
            setbtnop("1")
        }
    }

    const nextbtn = (e) => {
        e.preventDefault()
        if (!useremail) {
            console.log("please enter email!")
            toast("Please enter you email")
        } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(useremail)) {
            toast("Please enter proper email format")
        } else {
            setdata("page1")
            if (useremail !== "") {
                setmobilreg("mobilepage1")
                if (userpass === "") {
                    setbtnop("0.5")
                } else {
                    setbtnop("1")
                }
            } else {
                setmobilreg("mobilepage")
            }
        }
    }

    const openinputtext = (e) => {
        e.preventDefault()
        if ((e.target.value) !== "") {
            setbtnop("1")
        } else {
            setbtnop("0.5")
        }
        setuseremail(e.target.value)
    }
    const openinputtext1 = (e) => {
        e.preventDefault()
        if ((e.target.value) !== "") {
            setbtnop("1")
        } else {
            setbtnop("0.5")
        }
        setuserpass(e.target.value)
    }

    if (data === "page") {
        thedisp = <form onSubmit={nextbtn}>
            <input type="email" style={{ display: data }} value={useremail} onChange={(e) => setuseremail(e.target.value)} className="modal-invite" placeholder="Enter Your Email" />
        </form>
        thebtn = <div>
            <button className="modal-footer-btn" onClick={nextbtn}>Next</button>
        </div>
    } else if (data === "page1") {

        thedisp = <form onSubmit={logindata}>
            <input type="password" style={{ display: data }} value={userpass} onChange={(e) => setuserpass(e.target.value)} className="modal-invite" placeholder="Enter Password" />
        </form>
        thebtn = <div>
            <button className="modal-footer-btn2" onClick={firstpage}>Back</button>
            <button className="modal-footer-btn1" onClick={logindata}>Login</button>
        </div>
    }

    if (mobilereg === "mobilepage") {
        themobiledisp = <input type="email" placeholder="Enter Email" value={useremail} onChange={openinputtext} className="mobile-input-field" />
        themobilebtn = <div className="down-buttons">
            {/* <img src={BackArrowIcon} alt="back-arrow"/> */}
            <button className="mobile-next1" style={{ opacity: btnop }} onClick={nextbtn}>Next</button>
        </div>
    } else if (mobilereg === "mobilepage1") {
        themobiledisp = <input type="password" placeholder="Enter Password" value={userpass} onChange={openinputtext1} className="mobile-input-field" />
        themobilebtn = <div className="down-buttons">
            <img src={BackArrowIcon} alt="back-arrow" onClick={firstpage} />
            <button className="mobile-next" style={{ opacity: btnop }} onClick={logindata}>Next</button>
        </div>
    }

    return (
        <>
            {window.innerWidth > 600 ? (
                <>
                    {Loading ? (
                        <div className="regbg">
                            <div className="dispcard">
                                <div className="card">
                                    <img src={IndianNightLogo} alt="the-logo" className="img-size only-logo" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="regbg">
                            <div className="dispcard">
                                <div className="card">
                                    <img src={IndianNightLogo} alt="the-logo" className="img-size" />
                                    <br />
                                    {thedisp}
                                    {thebtn}
                                </div>
                            </div>
                            <ToastContainer />
                        </div>
                    )}
                </>
            ) : (
                <div className="regpage-mobile">
                    <Navbar />
                    <div className="mobile-input">
                        {themobiledisp}
                    </div>
                    {themobilebtn}
                </div>

            )
            }
        </>
    )
}

export default Login