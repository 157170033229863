import React, { useState } from "react";
import "./eventpage.css";
// import ShantiIcon from "../../static/images/logo/ShantiPeopleImg.png"
import Navbar from "../../components/Navbar"
// import { Tabs, Tab } from 'react-bootstrap-tabs';
import ClubIcon from "../../static/images/logo/clubfenicia.png"
import ShantiMobileIcon from "../../static/images/logo/shantimobileicon.png"
import RightArrow from "../../static/images/logo/rightarrow.png"

function EventPage() {
    const [dispdata, setdispdata] = useState("block")
    const [dispactive, setdispactive] = useState(" active")
    const [dispdata1, setdispdata1] = useState("none")
    const [dispactive1, setdispactive1] = useState("")
    // const [dispdata2, setdispdata2] = useState("none")
    // const [dispactive2, setdispactive2] = useState("")
    // const [dispdata3, setdispdata3] = useState("none")
    // const [dispactive3, setdispactive3] = useState("")
    // const [dispdata4, setdispdata4] = useState("none")
    // const [dispactive4, setdispactive4] = useState("")
    // const [dispdata5, setdispdata5] = useState("none")
    // const [dispactive5, setdispactive5] = useState("")

    const openCity = (e) => {
        console.log(e.target.value)
        if (e.target.value === "about") {
            setdispdata("block")
            setdispactive(" active")
            setdispdata1("none")
            setdispactive1("")
            // setdispdata2("none")
            // setdispactive2("")
        }
        if (e.target.value === "venue") {
            setdispdata("none")
            setdispactive("")
            setdispdata1("block")
            setdispactive1(" active")
            // setdispdata2("none")
            // setdispactive2("")
        }
    }
    return (
        <>
            <Navbar />
            {window.innerWidth > 768 ? (
                <div className="maindisp-img">
                    <div className="theimage-place">
                        {/* <img src = {ShantiIcon} alt = "shanti-icon" className = "shanti-img"/> */}
                    </div>
                    <div className="right-event-cont">
                        <p className="maintitle-event">Shanti People Live - Kolkata</p>
                        <div className="date-right">
                            <p className="event-month">MAY</p>
                            <p className="event-date">21</p>
                        </div>
                        <div className="hashes">
                            <p className="hashtags">#Music</p>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <p className="hashtags">#Kolkata</p>
                        </div>
                        <br />
                        <br />
                        {/* <Tabs onSelect={(index, label) => console.log(label + ' selected')}>
                        <Tab label="About">About</Tab>
                        <Tab label="Venue">Venue</Tab>
                    </Tabs> */}
                        <div className="tab">
                            <button className={"tablinks start-tab" + dispactive} value="about" onClick={openCity}>About</button>
                            <button className={"tablinks" + dispactive1} value="venue" onClick={openCity}>Venue</button>
                            <button className={"tablinks"} value="perform" onClick={openCity}>Performers</button>
                            <button className={"tablinks"} value="perform" onClick={openCity}>Menu</button>
                            <button className={"tablinks"} value="perform" onClick={openCity}>Feed</button>
                            <button className={"tablinks end-tab"} value="perform" onClick={openCity}>Rules</button>
                        </div>

                        <div className="tabcontent " style={{ display: dispdata }}>
                            <div className="event-content-style">
                                <p className="abt-title">Overview</p>
                                <p className="about-desc">Kolkata, Shanti People's first show in the city was simply amazing so second one is
                                certainly due! As you join in, you shall enjoy the immersive experience that you share
                                with all the like-minded music lovers of the city who're only there to enjoy the night &
                                make it memorable. And yes, Allways Events wants your experience to be pure, so we have
                                selected a top-notch venue & lined up stellar acts to complement their performance.
                                 </p>
                                <br />
                                <br />
                                <br />
                                <br />
                                <p className="abt-title">Why Should You Attend?</p>
                                <br />
                                <ol>
                                    <li className="about-points">Kolkata, Shanti People's first show in the city was simply amazing so second one is certainly </li>
                                    <li className="about-points">Kolkata, Shanti People's first show in the city was simply amazing so second one is certainly </li>
                                    <li className="about-points">Kolkata, Shanti People's first show in the city was simply amazing so second one is certainly </li>
                                </ol>
                            </div>
                        </div>

                        <div className="tabcontent" style={{ display: dispdata1 }}>
                            <div className="event-venue-style">
                                <div className="venue-data">
                                    <img src={ClubIcon} alt="club-fenicia" />
                                    <div className="data-text">
                                        <p className="venue-club">Club Fenicia</p>
                                        <p className="venue-city">Nightclub In Kolkata, West Bengal</p>
                                    </div>
                                </div>
                                <div className="venue-desc">
                                    <p>Club Fenicia offers a beautiful ambience with both indoor and outdoor seating.
                                    The outdoors have domes which can be used if you want a private seating space ,
                                    but they have additional cover charges. The entire atmosphere along with the music
                                makes the place a perfect spot for parties</p>
                                </div>
                                <br />
                                <hr />
                                <br />
                                <div>
                                    <p className="abt-title">Address</p>
                                    <p className="box-buttons">Copy</p>
                                    <p className="box-buttons share-btn">Share</p>
                                </div>
                                <br />
                                <p className="venue-details">Godrej Waterside, Tower- I, 10th Floor, DP Block, Sector 5, Salt Lake, Kolkata</p>
                            </div>
                        </div>

                        <div className="venue-footer">
                            <div className="footer-boxes">
                                <p className="box-data">For Men</p>
                                <p className="box-data">For Women</p>
                                <p className="box-data">For Groups</p>
                                <p className="box-data">For VIP's</p>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div>
                        <img src={ShantiMobileIcon} alt="shanti-icon" className="img-mobile" />
                    </div>
                    <div className="mobile-text">
                        <p>Shanti People Live - Kolkata</p>
                    </div>
                    <div className = "mob-date">
                        <p className = "mob-month">MAY</p>
                        <p className = "date-disp">21</p>
                    </div>
                    <div className = "mobile-hashes">
                        <p className = "mobile-tags">#Music</p>
                        &nbsp;&nbsp;&nbsp;
                        <p className = "mobile-tags">#Kolkata</p>
                    </div>
                    <div className = "arrow-drop">
                        <p className = "about-text-sty">About</p>
                        <img src = {RightArrow} alt = "right-arrow" className = "arrow-mobile"/>
                    </div>
                    <div className = "arrow-drop">
                        <p className = "about-text-sty">Venue</p>
                        <img src = {RightArrow} alt = "right-arrow" className = "arrow-mobile"/>
                    </div>
                    <div className = "arrow-drop">
                        <p className = "about-text-sty">Performers</p>
                        <img src = {RightArrow} alt = "right-arrow" className = "arrow-mobile"/>
                    </div>
                    <div className = "about-footer">
                        <p className = "bottom-mobile-text">For&nbsp;Men</p>
                        <p className = "bottom-mobile-text">For&nbsp;Women</p>
                        <p className = "bottom-mobile-text">For&nbsp;Groups</p>
                        <p className = "bottom-mobile-text">For&nbsp;VIP's</p>
                    </div>
                </div>
            )}

        </>
    )
}

export default EventPage