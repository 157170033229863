import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Register from "./pages/Register/register";
import Login from "./pages/Login/login";
// import Events from "./pages/Events/events"
import EventPage from "./pages/EventPage/eventpage";
import Home from "./pages/Sidebar/sidebar";
import Shop from "./pages/Shop/shop";
import NFTs from "./pages/NFTs/nfts";
import About from "./pages/About/about";
import Founders from "./pages/About/Founders/founders"

function MyRoutes(active:any) {
  console.log("route " + active)
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/registration" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/kolkata/shanti05-21" element={<EventPage />} />
        <Route path="/app/tickets" element={<Home />} />
        <Route path="/meet" element={<Shop />} />
        <Route path="/cards" element={<NFTs />} />
        <Route path="/aboutthecompany" element={<About/>}/>
        <Route path="/aboutthecompany/founders" element={<Founders />} />
      </Routes>
    </BrowserRouter>
  );
}

export default MyRoutes;
