import React from "react";
import "./events.scss";
// import Navbar from "../../components/Navbar";
// import Carousel from 'react-grid-carousel'
// import NetworkIcon from "../../static/images/bgs/networking.jpg"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ExporeEvents from "./ExploreEvents/exploreevents";
// import { Carousel } from 'react-responsive-carousel';
// import ShantiImg from "../../static/images/bgs/shanticarousel-img.png";
// import Carousel2 from "../../static/images/bgs/carousel2.png";
// import Carousel3 from "../../static/images/bgs/carousel3.png"
// import Performer1Icon from "../../static/images/logo/performer1.png";
// import Performer2Icon from "../../static/images/logo/performer2.png";
// import Performer3Icon from "../../static/images/logo/performer3.png";
// import Performer4Icon from "../../static/images/logo/performer4.png";
import Venue1Icon from "../../static/images/logo/venue1.png";
import Venue2Icon from "../../static/images/logo/venue2.png";
import Venue3Icon from "../../static/images/logo/venue3.png";
import ExplorePerformer from "./ExplorePerformers/exploreperformers";
import LookingToFind from "./LookingToFind/lookingtofind";
import ExploreVenue from "./ExploreVenues/explorevenue";
import EventFooter from "./EventFooter/eventfooter";



function Events({ active }) {
    // console.log(active)
    // let centeralignment
    // if (window.innerWidth > 768) {
    //     centeralignment = 53
    // } else {
    //     centeralignment = 80
    // }
    return (
        <>
            <ExporeEvents active={active} />
            <ExplorePerformer active={active} />
            <LookingToFind />
            <ExploreVenue active={active} />
            <EventFooter/>



            {/* <p className="title-one">Explore Events Across {active.charAt(0).toUpperCase() + active.slice(1)}</p>

            <div className="event-section-one">
                <Carousel
                    centerMode={true}
                    centerSlidePercentage={centeralignment}
                    infiniteLoop={true}
                    autoPlay={true}
                    dynamicHeight={400}>
                    <div className="the-main-carousel">
                        <img src={ShantiImg} alt="carousel-1" />
                    </div>
                    <div className="the-main-carousel">
                        <img src={Carousel2} alt="carousel-2" />
                    </div>
                    <div className="the-main-carousel">
                        <img src={Carousel3} alt="carousel-3" />
                    </div>
                </Carousel>

            </div> */}


            {/* <div className="event-section-two">
                <p className="title-two">Explore Performers Across {active.charAt(0).toUpperCase() + active.slice(1)}</p>
                <div className="performance-cards">
                    <div className="vertical-card">
                        <img src={Performer1Icon} alt="performer-1" className="the-img-performer" />
                    </div>
                    <div className="vertical-card">
                        <img src={Performer2Icon} alt="performer-2" className="the-img-performer" />
                    </div>
                    <div className="vertical-card">
                        <img src={Performer3Icon} alt="performer-3" className="the-img-performer" />
                    </div>
                    <div className="vertical-card">
                        <img src={Performer4Icon} alt="performer-4" className="the-img-performer" />
                    </div>
                </div>
            </div> */}
            {/* <div className="event-section-three">
                <p className="title-three">What Are You Looking To Find?</p>
                <div className="hub-cards">
                    <div className="img-cards">
                        <p className="hub-text">Pubs</p>
                    </div>
                    <div className="img-cards1">
                        <p className="hub-text">Nightclubs</p>
                    </div>
                    <div className="img-cards2">
                        <p className="hub-text">Concerts</p>
                    </div>
                    <div className="img-cards3">
                        <p className="hub-text">Restaurants</p>
                    </div>
                    <div className="img-cards4">
                        <p className="hub-text">Drive-Ins</p>
                    </div>
                </div>
            </div> */}
            {/* <div className="event-section-four">
                <p className="title-four">Explore Venues Across {active.charAt(0).toUpperCase() + active.slice(1)}</p>
                <div className="performance-cards right-margin">
                    <div className="vertical-card1">
                        <img src={Venue1Icon} alt="venue-1" className="venue-card" />
                    </div>
                    <div className="vertical-card1">
                        <img src={Venue2Icon} alt="venue-2" className="venue-card" />
                    </div>
                    <div className="vertical-card1">
                        <img src={Venue3Icon} alt="venue-3" className="venue-card" />
                    </div>
                    <div className="vertical-card1">

                    </div>
                </div>
            </div> */}
            {/* <div className="event-section-five">
                <p className="title-five"></p>
                <div className="stream-cards">
                    <div className="event-card-sec5">
                        <p className="the-text-btm">Build With IndianNights</p>
                    </div>
                    <div className="event-card-sec5">
                        <p className="the-text-btm">About IndianNights</p>
                    </div>
                    <div className="event-card-sec5 the-rightborder">
                        <p className="the-text-btm">Contact IndianNights</p>
                    </div>
                </div>
                <hr className="hr-tag" />
                <p className="footer-text">All Rights Reserved 2022 IndianNights.com</p>
            </div> */}
        </>
    )
}

export default Events