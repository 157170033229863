import React from "react";
import "../events.scss";
import Venue1Icon from "../../../static/images/logo/venue1.png";
import Venue2Icon from "../../../static/images/logo/venue2.png";
import Venue3Icon from "../../../static/images/logo/venue3.png";

function ExploreVenue({ active }) {
    let thedisp
    if (window.innerWidth < 768) {
        thedisp = "none"
    }
    return (
        <div className="event-section-four">
            <p className="title-four"><span style={{display:thedisp}}>Explore</span> Venues Across {active.charAt(0).toUpperCase() + active.slice(1)}</p>
            <div className="performance-cards right-margin">
                <div className="vertical-card1">
                    <img src={Venue1Icon} alt="venue-1" className="venue-card" />
                </div>
                <div className="vertical-card1">
                    <img src={Venue2Icon} alt="venue-2" className="venue-card" />
                </div>
                <div className="vertical-card1">
                    <img src={Venue3Icon} alt="venue-3" className="venue-card" />
                </div>
                <div className="vertical-card1">
                </div>
            </div>
        </div>
    )
}

export default ExploreVenue