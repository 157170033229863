import react , {createRef} from "react"
import classNames from "./navbar.module.scss";
import { FULL_LOGO } from "../../configs";
import MobileNav from "../../static/images/logo/mobilenav.png"
import { useState, useEffect } from "react"
import "./navbar.css"
import CloseIcon from "../../static/images/logo/closeicon.png"
import { useNavigate , Link } from 'react-router-dom';
import INlogo from "../../static/images/logos/INmobilelogo.png";
import ScrollSpy from 'react-scrollspy';




function Navbar(active: any) {
  console.log(active.active)
  let navigate = useNavigate()
  // console.log(props)
  // console.log(active)
  // active = "Abcd"
  const [thenav, setthenav] = useState(false)

  let thedisp
  if(active.active === "about" || active.active === "founders"){
    thedisp = "flex"
  }else{
    thedisp = "none"
  }
  if(window.innerWidth < 768){
    thedisp = "none"
  }


  const opennav = () => {
    // console.log("abcd")
    setthenav(true)
  }
  const closenav = () => {
    setthenav(false)
  }

  const crownbtn = () => {
    navigate("/login")
  };

  const eventbtn = () => {
    navigate("/")
  };
  const homebtn = () => {
    navigate("/")
  };
  const openshop = () => {
    navigate("/meet")
  }
  const opennft = () => {
    navigate("/cards")
  }
  const aboutbtn = () => {
    navigate("/aboutthecompany")
  }

  if (thenav) {
    return (
      <div className="navbarbgm" >
        <img src={CloseIcon} alt="close-icon" onClick={closenav} className="close-icon-sty" />
        {/* <div className={classNames.navbar} style={{ background: "none" }}>
          <div className={classNames.menu}>
            <div className={classNames.threedots}>
              <img src={CloseIcon} alt="close-icon" onClick={closenav} />
            </div>
          </div>
          <div className={classNames.logoWrap} onClick={homebtn}>
            <img src={FULL_LOGO} alt="" className={classNames.logo} />
          </div>
          <div className={classNames.menu}>
          </div>
        </div> */}
        {/* <div className={classNames.threedots}>
          <img src={CloseIcon} alt="close-icon" onClick={closenav} />
        </div> */}
        <div className="in-logo-sty">
          <img src={INlogo} alt="IN-logo" onClick={closenav} className="in-logo-sty" />
        </div>
        <br />
        <div>
          <p className={"the-fontclr" + (active.active === "events" ? " nav-active" : " ")} onClick={eventbtn}>Discover</p>
          <p className={"the-fontclr " + (active.active === "about" ? " nav-active" : " ")} onClick={aboutbtn}>About</p>
          <p className={"the-fontclr" + (active.active === "shop" ? " nav-active" : " ")} onClick={openshop}>Meet</p>
          <p className={"the-fontclr" + (active.active === "nfts" ? " nav-active" : " ")} onClick={opennft}>Cards</p>
          <p className="the-fontclr ">Venues</p>
          <p className="the-fontclr ">Performers</p>
        </div>
      </div>
    )
  } else {
    return (
      <>
        <div className={classNames.navbar}>
          <div className={classNames.menu}>
            <div className={classNames.threedots} onClick={opennav}>
              <img src={MobileNav} alt="" className={classNames.logo} />
            </div>
            <div className={classNames.menuItem + (active.active === "events" ? " nav-active" : " ")} onClick={eventbtn}>Discover</div>
            <div className={classNames.menuItem + (active.active === "about" ? " nav-active" : " ")} onClick={aboutbtn}>About</div>
            <div className={classNames.menuItem + (active.active === "shop" ? " nav-active" : " ")} onClick={openshop}>Meet</div>
            </div>
            {/* <ScrollSpy style={{display:thedisp}} className={classNames.menu + " the-nav-detail"} items={ ['founders'] }  currentClassName="isCurrent">
      
              <Link to = "/aboutthecompany/founders" className = "color-nav">Founders</Link>
              <li><a  className=" color-nav">Apps</a></li>
              <li><a className=" color-nav">Services</a></li>
    
            </ScrollSpy> */}

          <div className={classNames.logoWrap}>
            <img src={FULL_LOGO} alt="" className={classNames.logo} onClick={homebtn} />
          </div>
          <div className={classNames.menu}>
            <div className={classNames.menuItem + (active.active === "nfts" ? " nav-active" : " ")} onClick={opennft}>Cards</div>
            <div className={classNames.menuItem + " "}>Venues</div>
            <div className={classNames.menuItem + " "}>Performers</div>
          </div>
          {/* <div className={classNames.menu + " the-nav-detail"} style = {{display:thedisp}}>
              <li><a className=" color-nav">Media</a></li>
              <li><a className=" color-nav">Partners</a></li>
              <li><a className=" color-nav">Investors</a></li>
            </div> */}
        </div>
        {/* <div>
      <div className={classNames.menu + " themenu"}>
        <div className={classNames.menuItem}>Founders</div>
        <div className={classNames.menuItem}>Apps</div>
        <div className={classNames.menuItem}>Services</div>
      </div>
      </div> */}
      </>
    );
  }
}

export default Navbar;
