import React, { useState } from "react";
import classNames from "./landingPage.module.scss";
import hyd from "../../static/images/bgs/hyd.jpeg";
import kolkata from "../../static/images/bgs/kolkata.jpeg";
import bnglr from "../../static/images/bgs/bnglr.jpeg";
import mumbai from "../../static/images/bgs/mumbai.png";
import vizag from "../../static/images/bgs/vizag.jpeg";
// import chennai from "../../static/images/bgs/chennai.jpeg";
import pune from "../../static/images/bgs/pune.jpeg";
import delhi from "../../static/images/bgs/delhi.png";
import india from "../../static/images/bgs/india.png";
import Navbar from "../../components/Navbar";
// import IndianNightLogo from "../../static/images/logos/Indiannightlogo.png"
// import MainVideo from "./fullhdmain.mp4";
import MainVideo from "../../videos/fullhdmain.mp4";
// import VideoMob from "../../videos/MobileVideo.mp4"
// import 'antd/dist/antd.css';
// import React, { useState } from 'react';
// import { Modal } from 'antd';
import { useNavigate } from "react-router-dom";
import "./landing.scss";
import Events from "../Events/events";
import PartiesLogo from "../../static/images/logo/partieslogo.png";
import andoriod from "../../static/images/bgs/andoriod.svg";
import ios from "../../static/images/bgs/ios.svg";

function LandingPage() {
  const [logostate, setlogostate] = useState("India");
  let navigate = useNavigate();

  const showModal = () => {
    navigate("/registration");
  };

  // console.log(active)

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  const opencity = (e) => {
    console.log(e.target.id);
    setlogostate(e.target.id);
  };

  let thedata;
  let thedisp;
  let thebtn;
  let thetext;

  if (window.innerWidth > 768) {
    thedisp = "flex";
    thedata = (
      <>
        <div className={classNames.title}>
          Meet People <br />
          In Your City
          <wbr />
        </div>
        <div className="playStore">
          <img src={andoriod} />
          <div className="line"></div>
          <img src={ios} />
        </div>
      </>
    );
  } else {
    thedisp = "none";
    thedata = (
      <>
        <div className={classNames.title}>
          Meet People <br />
          In Your City
          <wbr />
        </div>
        <div className="playStore">
          <img src={andoriod} />
          <div className="line"></div>
          <img src={ios} />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={classNames.landingPage}>
        <Navbar active="events" />
        <div className={classNames.videoWrap}>
          <div className={classNames.videoOverlay}>
            {/* <div className={classNames.title}>
              Attend&nbsp;Exclusive Parties&nbsp; &amp; Access&nbsp;
              <wbr />
              The Hottest&nbsp;Venues
            </div>
            <div className={classNames.desc}>
              Become An IndianNights Member Today
            </div> */}
            {thedata}
            <div className={classNames.btns}>
              {/* <div className={classNames.btnHow}>
                <svg
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.93029 14.3736L13.878 9.91282L7.93029 5.45203V14.3736Z"
                    fill="white"
                  />
                  <path
                    d="M9.91287 0C4.43603 0 0 4.43603 0 9.91287C0 15.3897 4.43603 19.8257 9.91287 19.8257C15.3897 19.8257 19.8258 15.3898 19.8258 9.91287C19.8258 4.43598 15.3898 0 9.91287 0ZM9.91287 17.8432C5.54129 17.8432 1.98255 14.2845 1.98255 9.91287C1.98255 5.54129 5.54129 1.98259 9.91287 1.98259C14.2845 1.98259 17.8432 5.54129 17.8432 9.91287C17.8432 14.2845 14.2845 17.8432 9.91287 17.8432Z"
                    fill="white"
                  />
                </svg>
                How Will It Work
              </div> */}
              {/* <div className={classNames.btnGetStarted} onClick={showModal}>
                <svg
                  width="20"
                  height="25"
                  viewBox="0 0 20 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.332424 11.944C0.332424 11.944 1.29999 13.3654 3.32793 14.5686C3.32793 14.5686 1.85921 2.1558 11.4289 0C8.97708 8.99844 14.5476 11.5361 16.5782 6.78208C19.9692 11.3095 17.3945 15.2595 17.3945 15.2595C18.785 15.4598 19.9529 13.9408 19.9529 13.9408C19.9634 14.1279 19.9692 14.3163 19.9692 14.5061C19.9693 20.0204 15.499 24.4906 9.98464 24.4906C4.47029 24.4906 3.05176e-05 20.0204 3.05176e-05 14.506C3.05176e-05 13.6202 0.115978 12.7616 0.332424 11.944Z"
                    fill="white"
                  />
                  <path
                    d="M19.953 13.9408C19.953 13.9408 18.785 15.4598 17.3946 15.2595C17.3946 15.2595 19.9693 11.3095 16.5782 6.78208C14.5477 11.5361 8.97715 8.99844 11.4289 0C10.9164 0.115469 10.4358 0.260548 9.98466 0.43136V24.4906C15.499 24.4906 19.9693 20.0204 19.9693 14.506C19.9693 14.3162 19.9634 14.1279 19.953 13.9408Z"
                    fill="white"
                  />
                  <path
                    d="M5.6058 20.1117C5.6058 22.5301 7.5663 24.4906 9.98465 24.4906C12.403 24.4906 14.3635 22.5301 14.3635 20.1117C14.3635 18.8173 13.8019 17.6541 12.909 16.8525C11.216 19.1525 8.80336 15.6635 10.6836 13.1157C10.6836 13.1157 5.6058 13.752 5.6058 20.1117Z"
                    fill="#FAB544"
                  />
                  <path
                    d="M14.3635 20.1117C14.3635 18.8173 13.8019 17.6541 12.909 16.8525C11.216 19.1525 8.80338 15.6635 10.6836 13.1157C10.6836 13.1157 10.4077 13.1503 9.98466 13.2838V24.4906C12.403 24.4906 14.3635 22.5301 14.3635 20.1117Z"
                    fill="#FAB544"
                  />
                </svg>
                Join Waiting List
              </div> */}
              {thebtn}
            </div>
          </div>
        </div>
      </div>

      <div className="events-page-display">
        <p className="title-one-main">{thetext}</p>
        <div className="places-data">
          <div className="logos-data">
            <div
              className={
                "cityCard " + (logostate === "India" ? " card-active" : "")
              }
              id="india"
            >
              <img
                src={india}
                id="India"
                alt=""
                className="theround-image"
                onClick={opencity}
              />
              <span className="logo-text"> All India</span>
            </div>
            <div
              className={
                "cityCard " + (logostate === "hyderabad" ? " card-active" : "")
              }
            >
              <img
                src={hyd}
                id="hyderabad"
                alt=""
                className="theround-image"
                onClick={opencity}
              />
              <span className="logo-text">HYDERABAD</span>
            </div>
            <div
              className={
                "cityCard " + (logostate === "kolkata" ? " card-active" : "")
              }
            >
              <img
                src={kolkata}
                id="kolkata"
                alt=""
                className="theround-image"
                onClick={opencity}
              />
              <span className="logo-text">KOLKATA</span>
            </div>
            <div
              className={
                "cityCard " + (logostate === "bangalore" ? " card-active" : "")
              }
            >
              <img
                src={bnglr}
                id="bangalore"
                alt=""
                className="theround-image"
                onClick={opencity}
              />
              <span className="logo-text">BANGALORE</span>
            </div>
            <div
              className={
                "cityCard " + (logostate === "mumbai" ? " card-active" : "")
              }
            >
              <img
                src={mumbai}
                id="mumbai"
                alt=""
                className="theround-image"
                onClick={opencity}
              />
              <span className="logo-text">MUMBAI</span>
            </div>
            <div
              className={
                "cityCard " + (logostate === "vizag" ? " card-active" : "")
              }
            >
              <img
                src={vizag}
                id="vizag"
                alt=""
                className="theround-image"
                onClick={opencity}
              />
              <span className="logo-text">VIZAG</span>
            </div>
            <div
              className={
                "cityCard " + (logostate === "pune" ? " card-active" : "")
              }
            >
              <img
                src={pune}
                id="pune"
                alt=""
                className="theround-image"
                onClick={opencity}
              />
              <span className="logo-text">PUNE</span>
            </div>
            <div
              className={
                "cityCard " + (logostate === "new Delhi" ? " card-active" : "")
              }
            >
              <img
                src={delhi}
                id="new Delhi"
                alt=""
                className="theround-image"
                onClick={opencity}
              />
              <span className="logo-text">NEW DELHI</span>
            </div>
          </div>
        </div>
        <Events active={logostate} />
      </div>
    </>
  );
}

export default LandingPage;
