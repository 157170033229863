import React, { useState } from "react";
// import RegisterBgm from "../../static/images/bgs/registerbgm.png"
import "./register.css";
import IndianNightLogo from "../../static/images/logos/Indiannightlogo.png";
import Navbar from "../../components/Navbar";
import BackArrowIcon from "../../static/images/logo/BackArrow.png";
import { toast } from "react-toastify";
import ProfileImg from "../../static/images/icons/profileimg.png";
// import ReactCodeInput  from "react-input-verification-code";
import axios from "axios";
import useDigitInput from "react-digit-input";
import { useNavigate } from "react-router-dom";
import eyeIcon from "../../static/images/icons/eye.png";
import eyeSlash from "../../static/images/icons/eyeslash.png";

function Register(active) {
  let navigate = useNavigate();
  const [data, setdata] = useState("page0");
  const [textinvite, settextinvite] = useState("");
  const [mobemail, setmobemail] = useState("");
  const [mobnum, setmobnum] = useState("");
  const [mobusername, setmobusername] = useState("");
  const [mobpass, setmobpass] = useState("");
  const [mobcnfpass, setmobcnfpass] = useState("");
  const [btnop, setbtnop] = useState("0.5");
  const [mobilereg, setmobilreg] = useState("mobilepage0");
  const [theopacuser, settheopacuser] = useState("0.25");
  const [theopacemail, settheopacemail] = useState("0.25");
  const [invitetype, setinvitetype] = useState("");
  const [Loading, setLoading] = useState(false);
  const [inviteename, setinviteename] = useState("");
  const [inviteemail, setinviteemail] = useState("");
  const [inviteepic, setinviteepic] = useState("");
  const [affiliateId, setaffiliateId] = useState("");
  const [value, onChange] = useState("");
  const [newuser, setnewuser] = useState("");
  const [newmail, setnewmail] = useState("");
  const [newpic, setnewpic] = useState("");
  const [counter, setCounter] = useState(30);
  const [emaildisp, setemaildisp] = useState("red");
  const [theemailvalid, setemailvalid] = useState(false);
  const [userdisp, setuserdisp] = useState("red");
  const [theuservalid, setuservalid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [atParty, setAtParty] = useState(false);

  // const [thelength , setlength] = useState(0)
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 6,
    value,
    onChange,
  });

  console.log(value);

  let thedisp;
  let thebtn;

  let themobiledisp;
  let themobilebtn;

  // let thelength = 0
  // if(value){
  //     thelength = thelength + 1
  //     console.log(thelength)
  // }

  const firstpage = (e) => {
    setdata("page0");
    setmobilreg("mobilepage0");
    if (textinvite === "") {
      setbtnop("0.5");
    } else {
      setbtnop("1");
    }
    // if (mobemail !== "") {
    //     setmobilreg("mobilepage")
    // } else {
    //     setmobilreg("mobilepage1")
    // }
  };
  const nextbtn = (e) => {
    e.preventDefault();
    // console.log(mobnum)
    // if (textinvite !== "") {

    //     setmobilreg("mobilepage1")
    //     setdata("page1")
    //     if (mobnum === "") {
    //         setbtnop("0.5")
    //     } else {
    //         setbtnop("1")
    //     }
    // } else {
    //     setmobilreg("mobilepage")
    //     setdata("page")
    //     toast("please enter who invited you")
    // }
    if (!textinvite) {
      toast("please enter the invitee name or email");
    } else if (!invitetype) {
      toast("please select email or username");
    } else {
      setLoading(true);
      console.log("invite type-->" + invitetype.toLowerCase());
      console.log("invitee detail-->" + textinvite);
      axios
        .get(
          `https://comms.globalxchange.com/user/details/get?${invitetype.toLowerCase()}=${textinvite}`
        )
        .then((res) => {
          const { data } = res;
          console.log("data :>> ", data);
          setLoading(false);
          if (data.status) {
            console.log(res.data);
            setdata("page1.1");
            if (!mobnum) {
              setbtnop("0.5");
            } else {
              setbtnop("1");
            }
            setmobilreg("mobilepage1.1");
            setinviteename(res.data.user.username);
            setinviteemail(res.data.user.email);
            setaffiliateId(res.data.user.affiliate_id);
            if (!res.data.user.profile_img) {
              setinviteepic(ProfileImg);
            } else {
              setinviteepic(res.data.user.profile_img);
            }
          } else {
            toast("please enter proper user details");
          }
        });
    }
  };
  const nextbtn12 = (e) => {
    setdata("page1");
    setmobilreg("mobilepage1");
    if (!mobnum) {
      setbtnop("0.5");
    } else {
      setbtnop("1");
    }
  };
  const nextbtn1 = (e) => {
    e.preventDefault();
    console.log(mobnum);
    if (!mobnum) {
      setmobilreg("mobilepage1");
      setdata("page1");
      toast("please enter your number");
    } else if (mobnum.length !== 10) {
      setmobilreg("mobilepage1");
      setdata("page1");
      toast("Invalid Number");
    } else {
      setmobilreg("mobilepage2");
      setdata("page2");
      if (mobemail === "") {
        setbtnop("0.5");
      } else {
        setbtnop("1");
      }
    }
  };

  const nextbtn2 = (e) => {
    e.preventDefault();
    if (!theemailvalid) {
      setdata("page2");
      setmobilreg("mobilepage2");
    } else {
      if (mobemail !== "") {
        setmobilreg("mobilepage3");
        setdata("page3");
        if (mobusername === "") {
          setbtnop("0.5");
        } else {
          setbtnop("1");
        }
      } else {
        setdata("page2");
        setmobilreg("mobilepage2");
        toast("please enter email");
      }
    }
  };
  const nextbtn3 = (e) => {
    e.preventDefault();
    const speRegex = new RegExp(/^.*[!@#$%^&*()+=].*/);
    if (!theuservalid) {
      setdata("page3");
    } else {
      if (!mobusername) {
        setmobilreg("mobilepage3");
        setdata("page3");
        toast("please create a username");
        setuserdisp("red");
        setuservalid(false);
      } else if (speRegex.test(mobusername)) {
        toast("Username should not contain special characters");
        setuserdisp("red");
      } else {
        setdata("page4");
        setmobilreg("mobilepage4");
        if (mobpass === "") {
          setbtnop("0.5");
        } else {
          setbtnop("1");
        }
      }
    }
  };
  const nextbtn4 = (e) => {
    e.preventDefault();
    const capRegex = new RegExp(/^.*[A-Z].*/);
    const numRegex = new RegExp(/^.*[0-9].*/);
    const speRegex = new RegExp(/^.*[!@#$%^&*()+=].*/);
    if (!mobpass) {
      setmobilreg("mobilepage4");
      setdata("page4");
      toast("please enter password");
    } else if (!capRegex.test(mobpass)) {
      toast("Password should contain atleast one capital letter");
    } else if (!numRegex.test(mobpass)) {
      toast("Password should contain atleast one number");
    } else if (!speRegex.test(mobpass)) {
      toast("Password should contain atleast one special character");
    } else if (mobpass.length <= 8) {
      toast("Password should be more than 8 characters");
    } else {
      setmobilreg("mobilepage5");
      setdata("page5");
      if (mobcnfpass === "") {
        setbtnop("0.5");
      } else {
        setbtnop("1");
      }
    }
  };

  const nextbtn5 = (e) => {
    e.preventDefault();
    if (mobpass !== mobcnfpass) {
      toast("passwords do not match");
    } else {
      setLoading(true);
      let body = {
        username: mobusername,
        email: mobemail,
        password: mobpass,
        ref_affiliate: atParty ? "king" : affiliateId,
        signedup_app: "indiannights",
        mobile: mobnum,
      };
      console.log("register-details " + JSON.stringify(body));
      axios
        .post("https://gxauth.apimachine.com/gx/user/signup", body)
        .then((response) => {
          const { data } = response;
          setLoading(false);
          if (data.status) {
            console.log(response.data);
            setdata("page6");
            setmobilreg("mobilepage6");
          } else {
            toast(data.message);
          }
        });
    }
  };

  const openinputtext = (e) => {
    e.preventDefault();
    if (e.target.value !== "") {
      setbtnop("1");
    } else {
      setbtnop("0.5");
    }
    settextinvite(e.target.value);
  };
  const openinputtext1 = (e) => {
    e.preventDefault();
    if (e.target.value !== "") {
      setbtnop("1");
    } else {
      setbtnop("0.5");
    }
    setmobnum(e.target.value);
  };
  const openinputtext2 = (e) => {
    e.preventDefault();
    if (e.target.value !== "") {
      setbtnop("1");
    } else {
      setbtnop("0.5");
    }
    setmobemail(e.target.value);
  };

  const openinputtext3 = (e) => {
    e.preventDefault();
    if (e.target.value !== "") {
      setbtnop("1");
    } else {
      setbtnop("0.5");
    }
    setmobusername(e.target.value);
  };

  const openinputtext4 = (e) => {
    e.preventDefault();
    if (e.target.value !== "") {
      setbtnop("1");
    } else {
      setbtnop("0.5");
    }
    setmobpass(e.target.value);
  };

  const openinputtext5 = (e) => {
    e.preventDefault();
    if (e.target.value !== "") {
      setbtnop("1");
    } else {
      setbtnop("0.5");
    }
    setmobcnfpass(e.target.value);
  };

  const openinvitemail = (e) => {
    settheopacemail("1");
    settheopacuser("0.25");
    console.log("detail-->" + e.target.value.toLowerCase());
    setinvitetype(e.target.value);
  };
  const openinviteuser = (e) => {
    settheopacuser("1");
    settheopacemail("0.25");
    console.log("detail-->" + e.target.value.toLowerCase());
    setinvitetype(e.target.value);
  };

  const displaycode = (e) => {
    setLoading(true);
    console.log("code " + value);
    let body = {
      email: mobemail,
      code: value,
    };
    axios
      .post("https://gxauth.apimachine.com/gx/user/confirm", body)
      .then((response) => {
        const { data } = response;
        if (data.status) {
          console.log(response.data);
          axios
            .get(
              `https://comms.globalxchange.com/user/details/get?email=${mobemail}`
            )
            .then((res) => {
              console.log(res.data);
              setLoading(false);
              if (res.status) {
                setdata("page7");
                setmobilreg("mobilepage7");
                setnewuser(res.data.user.username);
                setnewmail(res.data.user.email);
                if (!res.data.user.profile_img) {
                  setnewpic(ProfileImg);
                } else {
                  setnewpic(res.data.user.profile_img);
                }
                localStorage.setItem("email", res.data.user.email);
                localStorage.setItem("username", res.data.user.username);
                localStorage.setItem("picimg", res.data.user.profile_img);
              } else {
                toast(res.data.message);
              }
            });
        } else {
          toast(data.message);
          setLoading(false);
        }
      });
  };

  const homepage = (e) => {
    navigate("/");
  };
  const ticketspage = (e) => {
    navigate("/app/tickets");
  };

  const resendbtn = (e) => {
    setLoading(true);
    let body = {
      email: mobemail,
    };
    axios
      .post("https://gxauth.apimachine.com/gx/user/confirm/resend", body)
      .then((response) => {
        const { data } = response;
        setLoading(false);
        setCounter(30);
        if (data.status) {
          toast("Verification code sent successfully");
        } else {
          toast("Something went wrong ,  please try again!!!");
        }
      });
  };

  const openemailvalid = (e) => {
    setmobemail(e.target.value);
    console.log(e.target.value);
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
      setemaildisp("red");
      setemailvalid(false);
      setbtnop("0.5");
    } else {
      axios
        .get(
          `https://comms.globalxchange.com/user/profile/data/get?email=${e.target.value}`
        )
        .then((response) => {
          const { data } = response;
          if (data.count === 0) {
            console.log("success--> " + JSON.stringify(data));
            setemaildisp("green");
            setemailvalid(true);
            setbtnop("1");
          } else {
            console.log("failed--> " + JSON.stringify(data));
            setemaildisp("red");
            setemailvalid(false);
            setbtnop("0.5");
            toast("email already registered");
          }
        });
    }
  };
  const openuservalid = (e) => {
    setmobusername(e.target.value);
    const speRegex = new RegExp(/^.*[!@#$%^&*()+=/].*/);
    if (e.target.value.length < 4) {
      setuserdisp("red");
      setuservalid(false);
      setbtnop("0.5");
      // toast("username should be atleast four characters")
    } else if (speRegex.test(e.target.value)) {
      toast("username should not contain special characters");
      setuserdisp("red");
      setuservalid(false);
      setbtnop("0.5");
    } else {
      axios
        .get(
          `https://comms.globalxchange.com/user/profile/data/get?username=${e.target.value}`
        )
        .then((response) => {
          const { data } = response;
          if (!data.status) {
            console.log("success--> " + JSON.stringify(data));
            setuserdisp("green");
            setuservalid(true);
            setbtnop("1");
          } else {
            console.log("failed--> " + JSON.stringify(data));
            setuserdisp("red");
            setuservalid(false);
            setbtnop("0.5");
          }
        });
    }
  };
  if (data === "page0") {
    thedisp = (
      <div style={{ padding: "50px 90px" }}>
        <div
          style={{
            color: "#212224",
            fontWeight: 700,
            fontSize: "25px",
            opacity: 0.25,
          }}
        >
          How Did You Find Us?
        </div>
        <div style={{ padding: "40px" }}>
          <div
            onClick={(e) => {
              setAtParty(true);
              nextbtn12();
            }}
            style={{
              display: "flex",
              alignItems: "center",
              border: "solid 1px lightgray",
              borderRadius: "15px",
              padding: "27px",
              cursor: "pointer",
            }}
          >
            <img
              src={require("../../static/images/logo/party.svg").default}
              alt=""
            />
            <div
              style={{
                fontWeight: 700,
                fontSize: "20px",
                color: "#212224",
                paddingLeft: "10px",
              }}
            >
              At Event
            </div>
          </div>

          <div
            onClick={(e) => setdata("page")}
            style={{
              marginTop: "30px",
              display: "flex",
              alignItems: "center",
              border: "solid 1px lightgray",
              borderRadius: "15px",
              padding: "27px",
              cursor: "pointer",
            }}
          >
            <img
              src={require("../../static/images/logo/affil.svg").default}
              alt=""
            />
            <div
              style={{
                fontWeight: 700,
                fontSize: "20px",
                color: "#212224",
                paddingLeft: "10px",
              }}
            >
              Affiliate
            </div>
          </div>
        </div>
      </div>
      // <form onSubmit={nextbtn}>
      //   <input
      //     type="text"
      //     style={{ display: data }}
      //     className="modal-invite"
      //     placeholder="Who Invited You?"
      //     value={textinvite}
      //     onChange={(e) => settextinvite(e.target.value)}
      //   />
      //   <br />
      //   <input
      //     type="button"
      //     value="Email"
      //     className="invite-name"
      //     onClick={openinvitemail}
      //     style={{ opacity: theopacemail }}
      //   />
      //   <input
      //     type="button"
      //     value="Username"
      //     className="invite-name"
      //     onClick={openinviteuser}
      //     style={{ opacity: theopacuser }}
      //   />
      // </form>
    );

    // themobiledisp = <input type="text" placeholder="Who Invited You?" value={textdata} onChange={openinputtext} className="mobile-input-field" />
    // themobilebtn = <div className="down-buttons">
    //     <img src={BackArrowIcon} alt="back-arrow" style={{ opacity: btnop }} />
    //     <button className="mobile-next" style={{ opacity: btnop }} onClick={nextbtn}>Next</button>
    // </div>
  } else if (data === "page") {
    thedisp = (
      <form onSubmit={nextbtn}>
        <input
          type="text"
          style={{ display: data }}
          className="modal-invite"
          placeholder="Who Invited You?"
          value={textinvite}
          onChange={(e) => settextinvite(e.target.value)}
        />
        <br />
        <input
          type="button"
          value="Email"
          className="invite-name"
          onClick={openinvitemail}
          style={{ opacity: theopacemail }}
        />
        <input
          type="button"
          value="Username"
          className="invite-name"
          onClick={openinviteuser}
          style={{ opacity: theopacuser }}
        />
      </form>
    );
    thebtn = (
      <div className="firstinvite-btn">
        <button className="modal-footer-btn" onClick={nextbtn}>
          Next
        </button>
      </div>
    );
    // themobiledisp = <input type="text" placeholder="Who Invited You?" value={textdata} onChange={openinputtext} className="mobile-input-field" />
    // themobilebtn = <div className="down-buttons">
    //     <img src={BackArrowIcon} alt="back-arrow" style={{ opacity: btnop }} />
    //     <button className="mobile-next" style={{ opacity: btnop }} onClick={nextbtn}>Next</button>
    // </div>
  } else if (data === "page1.1") {
    thedisp = (
      <div>
        <p className="invitee-title">Is This The Person Who Invited You?</p>
        <img src={inviteepic} alt="invitee-icon" className="invitee-img" />
        <br />
        <p className="invitee-name">{inviteename}</p>
        <p className="invitee-email">{inviteemail}</p>
      </div>
    );

    thebtn = (
      <div className="invitee-buttons">
        <button className="modal-footer-btn2" onClick={firstpage}>
          No
        </button>
        <button className="modal-footer-btn1" onClick={nextbtn12}>
          Yes
        </button>
      </div>
    );
  } else if (data === "page1") {
    thedisp = (
      <div>
        <form onSubmit={nextbtn1}>
          <span className="number-code">+91</span>
          <input
            type="number"
            style={{ display: data }}
            className="modal-invite"
            placeholder="Enter Your Number"
            value={mobnum}
            onChange={(e) => setmobnum(e.target.value)}
          />
        </form>
      </div>
    );
    thebtn = (
      <div>
        <button className="modal-footer-btn" onClick={nextbtn1}>
          Next
        </button>
      </div>
    );
    //   <button className="modal-footer-btn2" onClick={firstpage}>Back</button>
    // themobiledisp = <input type="text" placeholder="Enter Your Email" value={textdata1} onChange={openinputtext1} className="mobile-input-field" />
    // themobilebtn = <div className="down-buttons">
    //     <img src={BackArrowIcon} alt="back-arrow" style={{ opacity: btnop }} onClick={firstpage} />
    //     <button className="mobile-next" style={{ opacity: btnop }} onChange={nextbtn} onClick={nextbtn1}>Next</button>
    // </div>
  } else if (data === "page2") {
    thedisp = (
      <div>
        <form onSubmit={nextbtn2}>
          <input
            type="email"
            style={{ display: data }}
            className="modal-invite"
            placeholder="Enter Your Email"
            value={mobemail}
            onChange={openemailvalid}
          />
          <span className="dot" style={{ background: emaildisp }}></span>
        </form>
      </div>
    );
    thebtn = (
      <div>
        <button className="modal-footer-btn2" onClick={nextbtn12}>
          Back
        </button>
        <button className="modal-footer-btn1" onClick={nextbtn2}>
          Next
        </button>
      </div>
    );
  } else if (data === "page3") {
    thedisp = (
      <div>
        <form onSubmit={nextbtn3}>
          <input
            type="text"
            style={{ display: data }}
            className="modal-invite"
            placeholder="Create A Username"
            value={mobusername}
            onChange={openuservalid}
          />
          <span className="dot" style={{ background: userdisp }}></span>
        </form>
      </div>
    );
    thebtn = (
      <div>
        <button className="modal-footer-btn2" onClick={nextbtn1}>
          Back
        </button>
        <button className="modal-footer-btn1" onClick={nextbtn3}>
          Next
        </button>
      </div>
    );
  } else if (data === "page4") {
    thedisp = (
      <div>
        <form onSubmit={nextbtn4}>
          <input
            type={showPassword ? "text" : "password"}
            style={{ display: data }}
            className="modal-invite"
            placeholder="Enter A Password"
            value={mobpass}
            onChange={(e) => setmobpass(e.target.value)}
          />
          <img
            className="eye-display"
            onClick={() => {
              setShowPassword(!showPassword);
            }}
            src={showPassword ? eyeSlash : eyeIcon}
            alt=""
          />
        </form>
      </div>
    );
    thebtn = (
      <div>
        <button className="modal-footer-btn2" onClick={nextbtn2}>
          Back
        </button>
        <button className="modal-footer-btn1" onClick={nextbtn4}>
          Next
        </button>
      </div>
    );
  } else if (data === "page5") {
    thedisp = (
      <div>
        <form onSubmit={nextbtn5}>
          <input
            type={showPassword ? "text" : "password"}
            style={{ display: data }}
            className="modal-invite"
            placeholder="Confirm Password"
            value={mobcnfpass}
            onChange={(e) => setmobcnfpass(e.target.value)}
          />
          <img
            className="eye-display"
            onClick={() => {
              setShowPassword(!showPassword);
            }}
            src={showPassword ? eyeSlash : eyeIcon}
            alt=""
          />
        </form>
      </div>
    );
    thebtn = (
      <div>
        <button className="modal-footer-btn2" onClick={nextbtn3}>
          Back
        </button>
        <button className="modal-footer-btn1" onClick={nextbtn5}>
          Next
        </button>
      </div>
    );
  } else if (data === "page6") {
    let thetimerdata;
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000);
      thetimerdata = (
        <div className="resend-code-sty">
          <p>Resend Code In {counter} Seconds</p>
        </div>
      );
    } else {
      thetimerdata = (
        <div className="resend-code-text" onClick={resendbtn}>
          <p>Resend Code</p>
        </div>
      );
    }
    thedisp = (
      <div>
        <p className="email-code-title">Enter The Code You Got In Your Email</p>
        <div className="input-group" onKeyPress={displaycode}>
          <input
            type="tel"
            className="email-code-field"
            autoFocus
            {...digits[0]}
          />
          <input type="tel" className="email-code-field" {...digits[1]} />
          <input type="tel" className="email-code-field" {...digits[2]} />
          <input type="tel" className="email-code-field" {...digits[3]} />
          <input type="tel" className="email-code-field" {...digits[4]} />
          <input type="tel" className="email-code-field" {...digits[5]} />
        </div>
      </div>
    );
    thebtn = thetimerdata;
  } else if (data === "page7") {
    thedisp = (
      <div>
        <p className="invitee-title">Your Account Has Been Created</p>
        <img src={newpic} alt="invitee-icon" className="invitee-img" />
        <br />
        <p className="invitee-name">{newuser}</p>
        <p className="invitee-email">{newmail}</p>
      </div>
    );

    thebtn = (
      <div className="invitee-buttons">
        <button className="modal-footer-btn2" onClick={homepage}>
          Close
        </button>
        <button className="modal-footer-btn1" onClick={ticketspage}>
          Go To App
        </button>
      </div>
    );
  }

  if (mobilereg === "mobilepage0") {
    themobiledisp = (
      <div>
        <div
          style={{
            color: "#212224",
            fontWeight: 700,
            fontSize: "25px",
            opacity: 0.25,
          }}
        >
          How Did You Find Us?
        </div>
        <div style={{ padding: "40px" }}>
          <div
            onClick={(e) => {
              setAtParty(true);
              nextbtn12();
            }}
            style={{
              display: "flex",
              alignItems: "center",
              border: "solid 1px lightgray",
              borderRadius: "15px",
              padding: "27px",
            }}
          >
            <img
              src={require("../../static/images/logo/party.svg").default}
              alt=""
            />
            <div
              style={{
                fontWeight: 700,
                fontSize: "20px",
                color: "#212224",
                paddingLeft: "10px",
              }}
            >
              At Event
            </div>
          </div>
          <br />
          <div
            onClick={(e) => setmobilreg("mobilepage")}
            style={{
              display: "flex",
              alignItems: "center",
              border: "solid 1px lightgray",
              borderRadius: "15px",
              padding: "27px",
            }}
          >
            <img
              src={require("../../static/images/logo/affil.svg").default}
              alt=""
            />
            <div
              style={{
                fontWeight: 700,
                fontSize: "20px",
                color: "#212224",
                paddingLeft: "10px",
              }}
            >
              Affiliate
            </div>
          </div>
        </div>
      </div>
    );
    themobilebtn = (
      <div className="down-buttons">
        <button
          className="mobile-next1"
          style={{ opacity: btnop }}
          onClick={nextbtn}
        >
          Next
        </button>
      </div>
    );
  } else if (mobilereg === "mobilepage") {
    themobiledisp = (
      <div>
        <input
          type="text"
          placeholder="Who Invited You?"
          value={textinvite}
          onChange={openinputtext}
          className="mobile-input-field"
        />
        <input
          type="button"
          value="Email"
          className="invite-name"
          onClick={openinvitemail}
          style={{ opacity: theopacemail }}
        />
        <input
          type="button"
          value="Username"
          className="invite-name"
          onClick={openinviteuser}
          style={{ opacity: theopacuser }}
        />
      </div>
    );
    themobilebtn = (
      <div className="down-buttons">
        <button
          className="mobile-next1"
          style={{ opacity: btnop }}
          onClick={nextbtn}
        >
          Next
        </button>
      </div>
    );
  } else if (mobilereg === "mobilepage1.1") {
    themobiledisp = (
      <div>
        <p className="invitee-title">Is This The Person Who Invited You?</p>
        <img src={inviteepic} alt="invitee-icon" className="invitee-img" />
        <br />
        <p className="invitee-name">{inviteename}</p>
        <p className="invitee-email">{inviteemail}</p>
      </div>
    );

    themobilebtn = (
      <div className="invitee-buttons">
        <button className="modal-footer-btn2" onClick={firstpage}>
          No
        </button>
        <button className="modal-footer-btn1" onClick={nextbtn12}>
          Yes
        </button>
      </div>
    );
  } else if (mobilereg === "mobilepage1") {
    themobiledisp = (
      <div>
        <span className="number-code">+91</span>
        <input
          type="number"
          placeholder="Enter Your Number"
          value={mobnum}
          onChange={openinputtext1}
          className="mobile-input-field"
        />
      </div>
    );
    themobilebtn = (
      <div className="down-buttons">
        {/* <img src={BackArrowIcon} alt="back-arrow" onClick={firstpage} /> */}
        <button
          className="mobile-next1"
          style={{ opacity: btnop }}
          onClick={nextbtn1}
        >
          Next
        </button>
      </div>
    );
  } else if (mobilereg === "mobilepage2") {
    themobiledisp = (
      <div>
        <input
          type="email"
          placeholder="Enter Your Email"
          value={mobemail}
          onChange={openemailvalid}
          className="mobile-input-field"
        />
        <span className="dot" style={{ background: emaildisp }}></span>
      </div>
    );
    themobilebtn = (
      <div className="down-buttons">
        <img src={BackArrowIcon} alt="back-arrow" onClick={nextbtn12} />
        <button
          className="mobile-next"
          style={{ opacity: btnop }}
          onClick={nextbtn2}
        >
          Next
        </button>
      </div>
    );
  } else if (mobilereg === "mobilepage3") {
    themobiledisp = (
      <div>
        <input
          type="text"
          placeholder="Create A Username"
          value={mobusername}
          onChange={openuservalid}
          className="mobile-input-field"
        />
        <span className="dot" style={{ background: userdisp }}></span>
      </div>
    );
    themobilebtn = (
      <div className="down-buttons">
        <img src={BackArrowIcon} alt="back-arrow" onClick={nextbtn1} />
        <button
          className="mobile-next"
          style={{ opacity: btnop }}
          onClick={nextbtn3}
        >
          Next
        </button>
      </div>
    );
  } else if (mobilereg === "mobilepage4") {
    themobiledisp = (
      <input
        type="password"
        placeholder="Enter A Password"
        value={mobpass}
        onChange={openinputtext4}
        className="mobile-input-field"
      />
    );
    themobilebtn = (
      <div className="down-buttons">
        <img src={BackArrowIcon} alt="back-arrow" onClick={nextbtn2} />
        <button
          className="mobile-next"
          style={{ opacity: btnop }}
          onClick={nextbtn4}
        >
          Next
        </button>
      </div>
    );
  } else if (mobilereg === "mobilepage5") {
    themobiledisp = (
      <input
        type="password"
        placeholder="Confirm Password"
        value={mobcnfpass}
        onChange={openinputtext5}
        className="mobile-input-field"
      />
    );
    themobilebtn = (
      <div className="down-buttons">
        <img src={BackArrowIcon} alt="back-arrow" onClick={nextbtn3} />
        <button
          className="mobile-next"
          style={{ opacity: btnop }}
          onClick={nextbtn5}
        >
          Next
        </button>
      </div>
    );
  } else if (mobilereg === "mobilepage6") {
    let thetimerdata;
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000);
      thetimerdata = (
        <div className="resend-code-sty">
          <p style={{ opacity: 0.5 }}>Resend Code In {counter} Seconds</p>
          <button
            className="mobile-next1"
            style={{ opacity: 1 }}
            onClick={displaycode}
          >
            Next
          </button>
        </div>
      );
    } else {
      thetimerdata = (
        <div className="resend-code-text" onClick={resendbtn}>
          <p>Resend Code</p>
          <button
            className="mobile-next1"
            style={{ opacity: 1 }}
            onClick={displaycode}
          >
            Next
          </button>
        </div>
      );
    }
    themobiledisp = (
      <div>
        <p className="email-code-title">Enter The Code You Got In Your Email</p>
        <div className="input-group">
          <input
            type="tel"
            className="email-code-field"
            autoFocus
            {...digits[0]}
          />
          <input type="tel" className="email-code-field" {...digits[1]} />
          <input type="tel" className="email-code-field" {...digits[2]} />
          <input type="tel" className="email-code-field" {...digits[3]} />
          <input type="tel" className="email-code-field" {...digits[4]} />
          <input type="tel" className="email-code-field" {...digits[5]} />
        </div>
      </div>
    );
    themobilebtn = thetimerdata;
  } else if (mobilereg === "mobilepage7") {
    themobiledisp = (
      <div>
        <p className="invitee-title">Your Account Has Been Created</p>
        <img src={newpic} alt="invitee-icon" className="invitee-img" />
        <br />
        <p className="invitee-name">{newuser}</p>
        <p className="invitee-email">{newmail}</p>
      </div>
    );

    themobilebtn = (
      <div className="invitee-buttons">
        <button className="modal-footer-btn2" onClick={homepage}>
          Close
        </button>
        <button className="modal-footer-btn1" onClick={ticketspage}>
          Go To App
        </button>
      </div>
    );
  }

  return (
    <>
      {window.innerWidth > 600 ? (
        <>
          {Loading ? (
            <div className="regbg">
              <div className="dispcard">
                <div className="card">
                  <img
                    src={IndianNightLogo}
                    alt="the-logo"
                    className="img-size only-logo"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="regbg">
              <div className="dispcard">
                <div className="card">
                  <img
                    src={IndianNightLogo}
                    alt="the-logo"
                    className="img-size"
                  />
                  <br />
                  {thedisp}
                  {thebtn}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {Loading ? (
            <div>
              <Navbar active={active} />
              <div className="dispcard">
                <img
                  src={IndianNightLogo}
                  alt="the-logo"
                  className="img-size only-logo"
                />
              </div>
            </div>
          ) : (
            <div className="regpage-mobile">
              <Navbar active={active} />
              <div className="mobile-input">{themobiledisp}</div>
              {themobilebtn}
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Register;
