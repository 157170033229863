import React from "react";
import Performer1Icon from "../../../static/images/logo/performer1.png";
import Performer2Icon from "../../../static/images/logo/performer2.png";
import Performer3Icon from "../../../static/images/logo/performer3.png";
import Performer4Icon from "../../../static/images/logo/performer4.png";
import "../events.scss";



function ExplorePerformer({ active }) {
    let thedisp
    if (window.innerWidth < 768) {
        thedisp = "none"
    }
    return (
        <div className="event-section-two">
            <p className="title-two"><span style = {{display:thedisp}}>Explore</span> Performers Across {active.charAt(0).toUpperCase() + active.slice(1)}</p>
            <div className="performance-cards">
                <div className="vertical-card">
                    <img src={Performer1Icon} alt="performer-1" className="the-img-performer" />
                </div>
                <div className="vertical-card">
                    <img src={Performer2Icon} alt="performer-2" className="the-img-performer" />
                </div>
                <div className="vertical-card">
                    <img src={Performer3Icon} alt="performer-3" className="the-img-performer" />
                </div>
                <div className="vertical-card">
                    <img src={Performer4Icon} alt="performer-4" className="the-img-performer" />
                </div>
            </div>
        </div>
    )
}

export default ExplorePerformer