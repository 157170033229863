import React from "react";
import "../events.scss";


function LookingToFind(){
    
    let thedisp
    if (window.innerWidth > 768) {
        thedisp = "What Are You Looking To Find?"
    } else {
        thedisp = "What Are You Looking For?"
    }
    return (
        <div className="event-section-three">
        <p className="title-three">{thedisp}</p>
        <div className="hub-cards">
            <div className="img-cards">
                <p className="hub-text">Pubs</p>
            </div>
            <div className="img-cards1">
                <p className="hub-text">Nightclubs</p>
            </div>
            <div className="img-cards2">
                <p className="hub-text">Concerts</p>
            </div>
            <div className="img-cards3">
                <p className="hub-text">Restaurants</p>
            </div>
            <div className="img-cards4">
                <p className="hub-text">Drive-Ins</p>
            </div>
        </div>
    </div>
    )
}

export default LookingToFind