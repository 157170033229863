import React, { useEffect } from "react";
import "./about.scss";
import Navbar from "../../components/Navbar";
import ShorupanImg from "../../static/images/logo/shorupanImg.png";
import KrishnaImg from "../../static/images/logo/KrishnaImg.png";
import Founders from "./Founders/founders";
import AboutFooter from "./AboutFooter/aboutfooter";

import mobile from '../../static/images/bgs/mobile.svg'
function About() {
  let thedisp;
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  if (window.innerWidth > 768) {
    thedisp = "none";
  }
  return (
    <>
      <Navbar active="about" />
      <section id="the-about" key="1" className="the-full-width">
        <div className="the-about-data">
          <div className="left">
            <p className="the-about-tag">About The Company</p>
            <p className="the-about-rem">A Journey To Remember</p>
            <p className="the-about-desc">
              IndianNights is revolutionalize the nightlife industry across
              India. By offering a suite of management application to venues,
              performers, and event promoters, IndianNights aims to be the one
              stop shop for Indians looking for a great night out.
            </p>
          </div>
          <div className="right">
            <img src={mobile}/> 
          </div>
        </div>
      </section>
      {/* <AboutFooter style = {{display:thedisp}} status={true}/> */}
      {/* <Founders/> */}
    </>
  );
}

export default About;
