import React from "react";
import "../events.scss";
import { useNavigate , Link } from 'react-router-dom';

function EventFooter() {
    let navigate = useNavigate()
    let thedisp
    let thehrtag
    let theabt
    let themobdisp
    if (window.innerWidth > 768) {
        thedisp = "flex"
        themobdisp = "none"
    } else {
        thedisp = "none"
        thehrtag = "0.25px solid black"
        theabt = "none"
    }

    const openabout = () => {
        navigate("/aboutthecompany")
      };
    
    return (
        <div className="event-section-five">
            <p className="title-five" style = {{display:themobdisp}}></p>
            {/* <div className="stream-cards" style={{display:thedisp}}>
                <div className="event-card-sec5">
                    <p className="the-text-btm">Build With IndianNights</p>
                </div>
                <div className="event-card-sec5">
                    <p className="the-text-btm">About IndianNights</p>
                </div>
                <div className="event-card-sec5 the-rightborder">
                    <p className="the-text-btm">Contact IndianNights</p>
                </div>
            </div>
            <hr className="hr-tag" style={{border:thehrtag}}/> */}
            <div className="the-down-main" style={{display:themobdisp}}>
                <p className="the-event-foot-text" onClick={openabout}>About</p>
                <p className="the-event-foot-text">Contact</p>
                <p className="the-event-foot-text">Affiliates</p>
            </div>
            <hr className="hr-tag"/>
            <p className="footer-text">All Rights Reserved 2022 IndianNights.com</p>

            <button className="footer-text1" style={{display:theabt}} onClick={openabout}>About The Company</button>
        </div>
    )
}

export default EventFooter