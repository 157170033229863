import React, { useEffect, useState } from "react";
import "./sidebar.scss";

// import Navbar from "../../components/Navbar";
// import Sider from 'antd/lib/layout/Sider';
import { Link, useNavigate } from 'react-router-dom';
import "antd/dist/antd.less";
import "./sidebar.css";
import { Layout } from 'antd';
import IconOver from "../../static/images/logo/sideicon.png"
import DownArrow from "../../static/images/icons/downarrow.png"
import RightArrow from "../../static/images/icons/rightarrow.png"
import LockIcon from "../../static/images/icons/LockIcon.png";
import CollapseIcon from "../../static/images/icons/collapseicon.png"
import ExpandIcon from "../../static/images/icons/expandicon.png"
import TicketIcon from "../../static/images/logo/ticketimg.png"
import GroupIcon from "../../static/images/logo/groupimg.png"
import MemoriesIcon from "../../static/images/logo/memoriesimg.png"
import VaultsIcon from "../../static/images/logo/vaultsimg.png"
import SettingsIcon from "../../static/images/logo/settingsimg.png"
import ProfileIcon from "../../static/images/icons/profileimg.png"


const { Sider } = Layout;



function Home() {
    let active = "tickets"
    let navigate = useNavigate()
    const [collapsed, setCollapsed] = useState(false);
    const [email, setemail] = useState(localStorage.getItem('email') || '')
    const [username, setusername] = useState(localStorage.getItem('username') || '')

    useEffect(() => {
        if (!email) {
            navigate("/login")
        }
    }, [navigate , email]);

    const onCollapse = (collapsed) => {
        console.log(collapsed);
        setCollapsed(collapsed);
    };
    let profilepic = localStorage.getItem('picimg')

    if (!profilepic) {
        profilepic = ProfileIcon
    }



    const openlogin = (e) => {
        navigate("/login")
        localStorage.setItem('email', "");
        localStorage.setItem('username', "");
        localStorage.setItem('picimg', "")
    }

    return (
        <>
            <Layout
                style={{
                    minHeight: '100vh',
                    background: "white"
                }}
            >
                <Sider
                    collapsible
                    collapsed={collapsed}
                    onCollapse={onCollapse}
                    theme="light"
                    width={300}
                    trigger={null}
                >
                    <div className='logo'>
                        <img src={profilepic} alt="user-img" className={"user-icon " + collapsed} />
                        <div onClick={() => setCollapsed(!collapsed)}>
                            {collapsed ? <img src={ExpandIcon} alt="collapse-icon" className="expand-sty" /> : <img src={CollapseIcon} alt="collapse-icon" className="collapse-sty" />}
                        </div>
                        {collapsed ? <p></p> : <p className="the-name">{username}</p>}
                        {collapsed ? <p></p> : <p className="the-email">{email}</p>}
                    </div>
                    <div className={'sider-contents '}>
                        <div className={"group " + collapsed + "1"}>
                            <Link to="/app/tickets" className={
                                'menu-itm' + (active === 'tickets' ? ' active' : '')
                            }>{collapsed ? <><img src={TicketIcon} alt="ticket-icon" className="sider-collapse-icon" /><p className="tooltip-sty">Tickets</p></> : <><p className="side-names">Tickets</p><img src={DownArrow} alt="down-arrow" className="side-down-arrow" /></>}</Link>
                        </div>
                        <div className={"group " + collapsed + "1"}>
                            <Link to="/app/tickets" className={
                                'menu-itm' + (active === 'groups' ? ' active' : '')
                            }>{collapsed ? <><img src={GroupIcon} alt="group-icon" className="sider-collapse-icon" /><p className="tooltip-sty">Groups</p></> : <><p className="side-names">Groups</p><img src={DownArrow} alt="down-arrow" className="side-down-arrow" /></>}</Link>
                        </div>
                        <div className={"group " + collapsed + "1"}>
                            <Link to="/app/tickets" className={
                                'menu-itm' + (active === 'memories' ? ' active' : '')
                            }>{collapsed ? <><img src={IconOver} alt="icon-over" className="sider-collapse-icon" /><p className="tooltip-sty">Memories</p></> : <><p className="side-names">Memories</p><img src={DownArrow} alt="down-arrow" className="side-down-arrow" /></>}</Link>
                        </div>
                        <div className={"group " + collapsed + "1"}>
                            <Link to="/app/tickets" className={
                                'menu-itm' + (active === 'crown' ? ' active' : '')
                            }>{collapsed ? <><img src={MemoriesIcon} alt="memories-icon" className="sider-collapse-icon" /><p className="tooltip-sty">Crown</p></> : <><p className="side-names">Crown</p><img src={DownArrow} alt="down-arrow" className="side-down-arrow" /></>}</Link>
                        </div>
                        <div className={"group " + collapsed + "1"}>
                            <Link to="/app/tickets" className={
                                'menu-itm' + (active === 'vault' ? ' active' : '')
                            }>{collapsed ? <><img src={VaultsIcon} alt="vault-icon" className="sider-collapse-icon" /><p className="tooltip-sty">Vault</p></> : <><p className="side-names">Vaults</p><img src={RightArrow} alt="down-arrow" className="side-down-arrow" /></>}</Link>
                        </div>
                        <div className={"group " + collapsed + "1"}>
                            <Link to="/app/tickets" className={
                                'menu-itm' + (active === 'settings' ? ' active' : '')
                            }>{collapsed ? <><img src={SettingsIcon} alt="settings-icon" className="sider-collapse-icon" /><p className="tooltip-sty">Settings</p></> : <><p className="side-names">Settings</p><img src={RightArrow} alt="down-arrow" className="side-down-arrow" /></>}</Link>
                        </div>
                    </div>
                    {collapsed ?
                        "" :
                        <div className="lock-icon" onClick={openlogin}>
                            <img src={LockIcon} alt="lock-icon" className="lock-img" />
                            <span className="lock-text">Lock</span>
                        </div>}
                </Sider>
            </Layout>
        </>
    )
}

export default Home