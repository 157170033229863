import React from "react";
import { Carousel } from 'react-responsive-carousel';
import ShantiImg from "../../../static/images/bgs/shanticarousel-img.png";
import Carousel2 from "../../../static/images/bgs/carousel2.png";
import Carousel3 from "../../../static/images/bgs/carousel3.png"
import "../events.scss";



function ExporeEvents({ active }) {
    // active = "All india"

    let centeralignment
    let thedisp
    if (window.innerWidth > 768) {
        centeralignment = 53
    } else {
        centeralignment = 80
        thedisp = "none"
    }
    return (
        <>
            <p className="title-one"><span style = {{display:thedisp}}>Explore</span> Events Across {active.charAt(0).toUpperCase() + active.slice(1)}</p>
            <div className="event-section-one">
                <Carousel
                    centerMode={true}
                    centerSlidePercentage={centeralignment}
                    infiniteLoop={true}
                    autoPlay={true}
                    dynamicHeight={400}>
                    <div className="the-main-carousel">
                        <img src={ShantiImg} alt="carousel-1" />
                    </div>
                    <div className="the-main-carousel">
                        <img src={Carousel2} alt="carousel-2" />
                    </div>
                    <div className="the-main-carousel">
                        <img src={Carousel3} alt="carousel-3" />
                    </div>
                </Carousel>

            </div>
        </>
    )
}


export default ExporeEvents